import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { extendedTheme } from '../../providers/extendedTheme';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EuroIcon from '@mui/icons-material/Euro';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import DigitalMenu from '../../assets/digital_menu.jpg';
import Multilanguage from '../../assets/multilanguage.jpg';
import Customizable from '../../assets/customizable.jpg';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const iconStyle: SxProps<Theme> = {
  borderRadius: '100px',
  backgroundColor: (theme) =>
    extendedTheme.colorSchemes.light.palette.secondary.light,
};

function WhySection() {
  const { t } = useTranslation();
  return (
    <Box component="section" sx={{ bgcolor: 'background' }}>
      <Typography fontWeight={600} variant="h5" color={'secondary'} mt={10}>
        {t('why_qr_master_uppercase')}
      </Typography>

      <Typography
        fontWeight={500}
        // fontSize={{
        //   xs: 20,
        //   sm: 20,
        //   md: 30,
        // }}
        mt={2}
        pl={{
          xs: 5,
          sm: 10,
          md: 20,
        }}
        pr={{
          xs: 5,
          sm: 10,
          md: 20,
        }}
        variant="h5"
      >
        {t('the_service_allows_you_to_host')}
      </Typography>
      <Box
        component="section"
        sx={{ display: 'flex', overflow: 'hidden' }}
        mx={{
          xs: 3,
        }}
      >
        <Container
          sx={{ mt: 8, mb: 10, display: 'flex', position: 'relative' }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  pt: 0,
                  pb: 10,
                  paddingLeft: '0px',
                  paddingRight: '0px',

                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component={'img'}
                    sx={{
                      backgroundImage: `url(${DigitalMenu})`,
                      width: '100%',
                      height: '150px',
                      backgroundSize: 'cover',
                      borderRadius: '10px 10px 0px 0px',
                    }}
                  ></Box>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    variant="h6"
                    sx={{ my: 5, mb: 2 }}
                  >
                    {t('digital_menu')}
                  </Typography>
                  <Typography paddingLeft={5} paddingRight={5} variant="h5">
                    {t('digital_menu_info')}
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  pt: 0,
                  pb: 10,
                  paddingLeft: '0px',
                  paddingRight: '0px',

                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component={'img'}
                    sx={{
                      backgroundImage: `url(${Multilanguage})`,
                      width: '100%',
                      height: '150px',
                      backgroundSize: 'cover',
                      borderRadius: '10px 10px 0px 0px',
                    }}
                  ></Box>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    variant="h6"
                    sx={{ my: 5, mb: 2 }}
                  >
                    {t('multilanguage_title')}
                  </Typography>
                  <Typography paddingLeft={5} paddingRight={5} variant="h5">
                    {t('multilanguage_info')}
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                variant="outlined"
                sx={{
                  pt: 0,
                  pb: 10,
                  paddingLeft: '0px',
                  paddingRight: '0px',

                  height: '100%',
                  borderRadius: '10px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    component={'img'}
                    sx={{
                      backgroundImage: `url(${Customizable})`,
                      width: '100%',
                      height: '150px',
                      backgroundSize: 'cover',
                      borderRadius: '10px 10px 0px 0px',
                    }}
                  ></Box>
                  <Typography
                    paddingLeft={5}
                    paddingRight={5}
                    variant="h6"
                    sx={{ my: 5, mb: 2 }}
                  >
                    {t('customizable')}
                  </Typography>
                  <Typography paddingLeft={5} paddingRight={5} variant="h5">
                    {t('fully_customizable_info')}
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default WhySection;
