export default function PolicyEn() {
  return (
    <div>
      <h2>Privacy Policy</h2>

      <p>
        Last Updated: 24/03/2024 PiattoQR ("we", "us", or "our") respects your
        privacy and is committed to protecting it through our compliance with
        this policy. This Privacy Policy describes the types of information we
        may collect from you or that you may provide when you visit the PiattoQR
        website and our practices for collecting, using, maintaining,
        protecting, and disclosing that information.
      </p>

      <h2>Information We Collect and How We Use It</h2>

      <p>
        We may collect several types of information from and about users of our
        Service, including information:
      </p>

      <p>
        By which you may be personally identified, such as name, postal address,
        e-mail address, telephone number, or any other identifier by which you
        may be contacted online or offline ("personal information"). That is
        about you but individually does not identify you, such as your usage
        details, IP address, browser type, and information collected through
        cookies, web beacons, and other tracking technologies. About your
        internet connection, the equipment you use to access our Service, and
        usage details. We collect this information:
      </p>

      <p>
        Directly from you when you provide it to us. Automatically as you
        navigate through the site. Information collected automatically may
        include usage details, IP addresses, and information collected through
        cookies, web beacons, and other tracking technologies. From third
        parties, for example, our business partners. How We Use Your Information
      </p>

      <p>
        We use information that we collect about you or that you provide to us,
        including any personal information:
      </p>

      <p>
        To present our Service and its contents to you. To provide you with
        information, products, or services that you request from us. To fulfill
        any other purpose for which you provide it. To carry out our obligations
        and enforce our rights arising from any contracts entered into between
        you and us, including for billing and collection. To notify you about
        changes to our Service or any products or services we offer or provide
        though it. In any other way we may describe when you provide the
        information. For any other purpose with your consent. We may also use
        your information to contact you about our own and third-parties' goods
        and services that may be of interest to you. If you do not want us to
        use your information in this way, please check the relevant box located
        on the form on which we collect your data (the registration form or
        checkout page). For more information, see "Choices About How We Use and
        Disclose Your Information".
      </p>

      <h2>Disclosure of Your Information</h2>

      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual, without restriction.
      </p>

      <p>
        We may disclose personal information that we collect or you provide as
        described in this privacy policy:
      </p>

      <p>
        To our subsidiaries and affiliates. To contractors, service providers,
        and other third parties we use to support our business. To a buyer or
        other successor in the event of a merger, divestiture, restructuring,
        reorganization, dissolution, or other sale or transfer of some or all of
        PiattoQR's assets, whether as a going concern or as part of bankruptcy,
        liquidation, or similar proceeding, in which personal information held
        by PiattoQR about our Service users is among the assets transferred. To
        fulfill the purpose for which you provide it. For any other purpose
        disclosed by us when you provide the information. With your consent. To
        comply with any court order, law, or legal process, including to respond
        to any government or regulatory request. To enforce or apply our Terms
        of Use and other agreements, including for billing and collection
        purposes. If we believe disclosure is necessary or appropriate to
        protect the rights, property, or safety of PiattoQR, our customers, or
        others. This includes exchanging information with other companies and
        organizations for the purposes of fraud protection and credit risk
        reduction. Choices About How We Use and Disclose Your Information
      </p>

      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with
        control over your information:
      </p>

      <p>
        Tracking Technologies and Advertising. You can set your browser to
        refuse all or some browser cookies, or to alert you when cookies are
        being sent. If you disable or refuse cookies, please note that some
        parts of this site may then be inaccessible or not function properly.
      </p>

      <p>
        Promotional Offers from the Company. If you do not wish to have your
        email address/contact information used by the Company to promote our own
        or third parties' products or services, you can opt-out by checking the
        relevant box located on the form on which we collect your data or by
        sending us an email stating your request to [insert email address]. If
        we have sent you a promotional email, you may send us a return email
        asking to be omitted from future email distributions. This opt-out does
        not apply to information provided to the Company as a result of a
        product purchase, warranty registration, product service experience, or
        other transactions.
      </p>

      <h2>Accessing and Correcting Your Information</h2>

      <p>
        You can review and change your personal information by logging into the
        website and visiting your account profile page.
      </p>

      <p>
        You may also send us an email at [insert email address] to request
        access to, correct, or delete any personal information that you have
        provided to us. We cannot delete your personal information except by
        also deleting your user account. We may not accommodate a request to
        change information if we believe the change would violate any law or
        legal requirement or cause the information to be incorrect.
      </p>

      <p>
        If you delete your User Contributions from the Service, copies of your
        User Contributions may remain viewable in cached and archived pages, or
        might have been copied or stored by other Service users.
      </p>

      <h2>Data Security</h2>

      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration, and disclosure. All information you provide to us is stored
        on our secure servers behind firewalls.
      </p>

      <p>
        The safety and security of your information also depend on you. Where we
        have given you (or where you have chosen) a password for access to
        certain parts of our Service, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone. We urge you to be careful about giving out information in public
        areas of the Service like message boards. The information you share in
        public areas may be viewed by any user of the Service.
      </p>

      <h2>Changes to Our Privacy Policy</h2>

      <p>
        It is our policy to post any changes we make to our privacy policy on
        this page. If we make material changes to how we treat our users'
        personal information, we will notify you through a notice on the website
        home page. The date the privacy policy was last revised is identified at
        the top of the page. You are responsible for ensuring we have an
        up-to-date active and deliverable email address for you, and for
        periodically visiting our website and this privacy policy to check for
        any changes.
      </p>

      <h2>Contact Information</h2>

      <p>
        To ask questions or comment about this privacy policy and our privacy
        practices, contact us at: alejandro.hernandez@piattoqr.com.
      </p>
    </div>
  );
}
