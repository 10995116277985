import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import withRoot from '../withRoot';
import PricingCard from '../components/PricingCard';
import { useTranslation } from 'react-i18next';

function PricingPage() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Container
        id="pricing"
        component="section"
        sx={{
          px: {
            xs: 5,
            sm: 10,
            md: 20,
            lg: 20,
            xl: 20,
          },
          py: '60px',
        }}
      >
        <Typography variant="h4" align="center" component="h2">
          {t('pricing_uppercase')}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns:
              'repeat(auto-fill, minmax(min(100%, 250px), 1fr))',
            gap: 3,
            mt: 10,
          }}
        >
          <PricingCard
            title={t('always_free')}
            features={[`1 ${t('menu')}`, `300 ${t('visits')} / ${t('month')}`]}
            description=""
            price={t('free')}
            label={t('free_uppercase')}
            buttonText={t('start_uppercase')}
            headerColor="white"
            onClick={() => {}}
            isFree={true}
            href={`${process.env.REACT_APP_ADMIN_URL}/register?plan=free`}
          />

          <PricingCard
            title="Basic"
            features={[
              `3 ${t('menus')}`,
              `2000 ${t('visits')} / ${t('month')}`,
              t('product_images_and_variations'),
              t('basic_support'),
            ]}
            description=""
            price="5.99"
            label={t('starter_uppercase')}
            headerColor="white"
            buttonText={t('start_uppercase')}
            onClick={() => {}}
            isFree={false}
            href={`${process.env.REACT_APP_ADMIN_URL}/register?plan=basic`}
          />

          <PricingCard
            title="Pro"
            features={[
              `15 ${t('menus')}`,
              t('unlimited_visits'),
              t('product_images_and_variations'),
              t('multilanguage'),
              t('pdf_menus'),
              t('customized_support'),
            ]}
            description=""
            price="9.99"
            label={t('most_common_uppercase')}
            buttonText={t('start_uppercase')}
            onClick={() => {}}
            headerColor="secondary.main"
            isFree={false}
            href={`${process.env.REACT_APP_ADMIN_URL}/register?plan=pro`}
          />
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default withRoot(PricingPage);
