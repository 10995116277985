import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import { Grid, Paper } from '@mui/material';

import presenation01 from '../../assets/presentation-image-01.png';
import presentation02 from '../../assets/presentation02.png';
import presentation03 from '../../assets/presentation03.png';
import presentation04 from '../../assets/presentation04.png';
import { useTranslation } from 'react-i18next';

import Feature01English from '../../assets/feature_01_english.png';
import Feature01Spanish from '../../assets/feature_01_spanish.png';
import Feature01Italian from '../../assets/feature_01_italian.png';

import Feature02English from '../../assets/feature_02_english.png';
import Feature02Spanish from '../../assets/feature_02_spanish.png';
import Feature02Italian from '../../assets/feature_02_italian.png';

import Feaure03English from '../../assets/feature_03_english.png';
import Feaure03Spanish from '../../assets/feature_03_spanish.png';
import Feaure03Italian from '../../assets/feature_03_italian.png';

import Feaure04English from '../../assets/feature_04_english.png';
import Feaure04Spanish from '../../assets/feature_04_spanish.png';
import Feaure04Italian from '../../assets/feature_04_italian.png';

export default function ProductCategories() {
  const { t, i18n } = useTranslation();

  function localizedFeature01() {
    if (i18n.language == 'en') {
      return Feature01English;
    } else if (i18n.language == 'es') {
      return Feature01Spanish;
    } else if (i18n.language == 'it') {
      return Feature01Italian;
    }

    return Feature01English;
  }

  function localizedFeature02() {
    if (i18n.language == 'en') {
      return Feature02English;
    } else if (i18n.language == 'es') {
      return Feature02Spanish;
    } else if (i18n.language == 'it') {
      return Feature02Italian;
    }

    return Feature02English;
  }

  function localizedFeature03() {
    if (i18n.language == 'en') {
      return Feaure03English;
    } else if (i18n.language == 'es') {
      return Feaure03Spanish;
    } else if (i18n.language == 'it') {
      return Feaure03Italian;
    }

    return Feaure03English;
  }

  function localizedFeature04() {
    if (i18n.language == 'en') {
      return Feaure04English;
    } else if (i18n.language == 'es') {
      return Feaure04Spanish;
    } else if (i18n.language == 'it') {
      return Feaure04Italian;
    }

    return Feaure04English;
  }

  return (
    <Container
      id="features"
      component="section"
      maxWidth={false}
      sx={{ mt: 8, mb: 4, bgcolor: 'white', pt: 10, pb: 10 }}
    >
      <Typography variant="h4" align="center" component="h2">
        {t('features')}
      </Typography>

      <Container
        sx={{
          mt: 20,
          display: 'flex',

          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              border={'1px solid rgb(200, 200, 200)'}
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${localizedFeature01()})`,
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {t('customizable_menu_uppercase')}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {t('configure_the_menu_the_way_you_want_it')}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {t('the_platform_allows')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            order={{
              xs: 1,
              sm: 1,
              md: 0,
              lg: 0,
              xl: 0,
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {t('multilanguage_segmented')}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {t('see_the_menu_in_a_variety_of_languages')}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {t('to_be_flexible_with_a_lot')}
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${localizedFeature02()})`,
                backgroundPosition: 'left',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },
            }}
          >
            {/* <Paper elevation={20}> */}
            <Box
              border={'1px solid rgb(200, 200, 200)'}
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                //  bgcolor: 'gray',
                backgroundImage: `url(${localizedFeature03()})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
            {/* </Paper> */}
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {t('pdf_menu_uppercase')}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {t('use_the_pdf_of_your_menu')}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {t('if_you_dont_want')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container
        sx={{
          mt: 25,
          mb: 10,
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center-top',
        }}
      >
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pr: {
                xs: 0,
                sm: 0,
                md: 5,
                lg: 5,
                xl: 5,
              },

              mt: {
                xs: 5,
                sm: 5,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
            order={{
              xs: 1,
              sm: 1,
              md: 0,
              lg: 0,
              xl: 0,
            }}
          >
            <Box>
              <Typography
                color="primary"
                fontWeight={600}
                fontSize={16}
                textAlign={'left'}
                pb={2}
              >
                {t('allergens_uppercase')}
              </Typography>

              <Typography fontWeight={600} fontSize={40} textAlign={'left'}>
                {t('allergen_disclousure_on_your_menus')}
              </Typography>

              <Typography
                fontWeight={500}
                fontSize={20}
                textAlign={'left'}
                mt={2}
              >
                {t('allergen_info')}
              </Typography>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              pl: {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 5,
                xl: 5,
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                aspectRatio: 1.5,
                borderRadius: '10px',
                bgcolor: 'gray',
                backgroundImage: `url(${localizedFeature04()})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
