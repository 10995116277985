import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

function ProductCTA() {
  const { t } = useTranslation();
  return (
    <Container
      id="FAQ"
      maxWidth={false}
      component="section"
      sx={{
        mt: 10,
        bgcolor: 'white',
        pt: 10,
        pl: {
          xs: 5,
          sm: 5,
          md: 5,
          lg: 20,
          xl: 20,
        },
        pr: {
          xs: 5,
          sm: 5,
          md: 5,
          lg: 20,
          xl: 20,
        },
      }}
      disableGutters
    >
      <Typography variant="h4" align="center" component="h2" marginBottom={7}>
        {t('commonly_asked_questions')}
      </Typography>

      <Accordion
        defaultExpanded
        sx={{
          mt: 10,
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            borderRadius: 1,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {t('whats_piatto_qr')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>
            {t('whats_piatto_qr_info')}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {t('how_it_works')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>{t('how_it_works_info')}</Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {t('how_can_i_update_my_dishes')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>
            {t('how_can_i_update_my_dishes_info')}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        elevation={0}
        sx={{
          '& .MuiAccordionSummary-root': {
            py: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'start'} fontWeight={700} fontSize={20}>
            {t('there_is_a_free_version')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: 0.7,
          }}
        >
          <Typography textAlign={'left'}>
            {t('there_is_a_free_version_info')}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default ProductCTA;
