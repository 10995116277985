export default function TermsEn() {
  return (
    <div>
      <h2>Terms and Conditions</h2>

      <p>Last Updated: 24/03/2024</p>

      <p>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions")
        carefully before using the PiattoQR website and the services operated by
        PiattoQR (collectively referred to as the "Service").
      </p>

      <h2>1. Agreement to Terms</h2>

      <p>
        By accessing or using the Service, you agree to be bound by these Terms.
        If you disagree with any part of the Terms, then you may not access the
        Service.
      </p>

      <h2>2. Intellectual Property</h2>

      <p>
        The Service and its original content, features, and functionality are
        and will remain the exclusive property of PiattoQR and its licensors.
        The Service is protected by copyright, trademark, and other laws of both
        the [insert jurisdiction] and foreign countries. Our trademarks and
        trade dress may not be used in connection with any product or service
        without the prior written consent of PiattoQR.
      </p>

      <h2>3. Use of the Service</h2>

      <p>
        3.1. PiattoQR grants you a limited, non-exclusive, non-transferable, and
        revocable license to access and use the Service for your personal or
        internal business purposes.
      </p>

      <h2>3.2. You agree not to:</h2>

      <p>
        Modify, adapt, translate, or reverse engineer any portion of the
        Service. Use the Service for any unlawful purpose or in violation of any
        applicable laws. Interfere with or disrupt the integrity or performance
        of the Service or any related servers or networks. Attempt to gain
        unauthorized access to the Service or any related systems or networks.
      </p>
      <h2>4. User Accounts</h2>

      <p>
        4.1. To access certain features of the Service, you may be required to
        create an account. You are responsible for maintaining the
        confidentiality of your account and password and for restricting access
        to your account.
      </p>

      <p>
        4.2. You agree to accept responsibility for all activities that occur
        under your account or password.
      </p>

      <h2>5. Termination</h2>

      <p>
        We may terminate or suspend your access to the Service immediately,
        without prior notice or liability, for any reason whatsoever, including
        without limitation if you breach the Terms.
      </p>

      <p>
        Upon termination, your right to use the Service will immediately cease.
      </p>

      <h2>6. Limitation of Liability</h2>

      <p>
        In no event shall PiattoQR, nor its directors, employees, partners,
        agents, suppliers, or affiliates, be liable for any indirect,
        incidental, special, consequential, or punitive damages, including
        without limitation, loss of profits, data, use, goodwill, or other
        intangible losses, resulting from (i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of
        any third party on the Service; (iii) any content obtained from the
        Service; and (iv) unauthorized access, use, or alteration of your
        transmissions or content, whether based on warranty, contract, tort
        (including negligence), or any other legal theory, whether or not we
        have been informed of the possibility of such damage, and even if a
        remedy set forth herein is found to have failed of its essential
        purpose.
      </p>

      <h2>7. Governing Law</h2>

      <p>
        These Terms shall be governed and construed in accordance with the laws
        of [insert jurisdiction], without regard to its conflict of law
        provisions.
      </p>

      <p>
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect.
      </p>

      <h2>8. Changes</h2>

      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will try to provide at
        least 30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </p>

      <p>
        By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, please stop using the Service.
      </p>

      <h2>9. Contact Us</h2>

      <p>
        If you have any questions about these Terms, please contact us at
        alejandro.hernandez@piattoqr.com.
      </p>
    </div>
  );
}
