import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import { Box, Container } from '@mui/material';
import { extendedTheme } from '../../providers/extendedTheme';
import { useTranslation } from 'react-i18next';

import PresentationEnglish from '../../assets/presentation_english.png';
import PresentationSpanish from '../../assets/presentation_spanish.png';
import PresentationItalian from '../../assets/presentation_italian.png';

export default function ProductHero() {
  const { t, i18n } = useTranslation();

  function localizedPresentation() {
    if (i18n.language == 'en') {
      return PresentationEnglish;
    } else if (i18n.language == 'es') {
      return PresentationSpanish;
    } else if (i18n.language == 'it') {
      return PresentationItalian;
    }

    return PresentationEnglish;
  }
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundColor: 'white',
      }}
    >
      <Container
        id="home"
        sx={{
          px: {
            xs: 5,
            sm: 10,
            md: 30,
            lg: 30,
            xl: 30,
          },
        }}
      >
        <Typography
          fontSize={{
            xs: 45,
            sm: 45,
            md: 45,
            lg: 55,
            xl: 55,
          }}
          fontWeight={700}
        >
          {t('qr_menu_for_your_restaurant')}
        </Typography>
        <Typography
          color="black"
          align="center"
          variant="h5"
          sx={{
            mb: 10,

            mt: { xs: 1, sm: 1 },
          }}
        >
          {t('qr_menu_for_your_restaurant_info')}
        </Typography>
      </Container>
      <div>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          href={`${process.env.REACT_APP_ADMIN_URL}/register`}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            minWidth: 200,
            color: 'white',
            mb: 4,
            borderRadius: '5px',
          }}
        >
          {t('create_menu')}
        </Button>

        <Button
          color="secondary"
          variant="outlined"
          size="large"
          component="a"
          href={`${process.env.REACT_APP_ADMIN_URL}/t119K3a`}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            minWidth: 200,
            color: (t) =>
              extendedTheme.colorSchemes.light.palette.secondary.light,
            mb: 4,
            borderRadius: '5px',
          }}
        >
          {t('seeDemo')}
        </Button>
      </div>
      <div></div>

      <Box
        component="img"
        src={localizedPresentation()}
        sx={{ mt: 5 }}
        className="menu-image"
      />
    </ProductHeroLayout>
  );
}
