export default function PolicyEs() {
  return (
    <div>
      <h2>Política de Privacidad</h2>

      <p>
        Última Actualización: 24/03/2024 PiattoQR ("nosotros", "nos" o
        "nuestro") respeta tu privacidad y se compromete a protegerla a través
        del cumplimiento de esta política. Esta Política de Privacidad describe
        los tipos de información que podemos recopilar de ti o que puedes
        proporcionar cuando visitas el sitio web de PiattoQR y nuestras
        prácticas para recopilar, usar, mantener, proteger y divulgar esa
        información.
      </p>

      <h2>Información que Recopilamos y Cómo la Usamos</h2>

      <p>
        Podemos recopilar varios tipos de información de y sobre los usuarios de
        nuestro Servicio, incluyendo información:
      </p>

      <p>
        Mediante la cual puedas ser identificado personalmente, como nombre,
        dirección postal, dirección de correo electrónico, número de teléfono u
        otro identificador con el que puedas ser contactado en línea o fuera de
        línea ("información personal"). Que sea sobre ti pero no te identifique
        individualmente, como tus detalles de uso, dirección IP, tipo de
        navegador e información recopilada a través de cookies, balizas web y
        otras tecnologías de seguimiento. Sobre tu conexión a internet, el
        equipo que utilizas para acceder a nuestro Servicio y detalles de uso.
        Recopilamos esta información:
      </p>

      <p>
        Directamente de ti cuando nos la proporcionas. Automáticamente mientras
        navegas por el sitio. La información recopilada automáticamente puede
        incluir detalles de uso, direcciones IP e información recopilada a
        través de cookies, balizas web y otras tecnologías de seguimiento. De
        terceros, por ejemplo, nuestros socios comerciales.
      </p>

      <p>Cómo Usamos Tu Información</p>

      <p>
        Usamos la información que recopilamos sobre ti o que nos proporcionas,
        incluyendo cualquier información personal:
      </p>

      <p>
        Para presentar nuestro Servicio y su contenido para ti. Para
        proporcionarte información, productos o servicios que nos solicites.
        Para cumplir cualquier otro propósito para el cual la proporciones. Para
        cumplir con nuestras obligaciones y hacer valer nuestros derechos
        derivados de cualquier contrato celebrado entre tú y nosotros,
        incluyendo para facturación y cobro. Para notificarte sobre cambios en
        nuestro Servicio o cualquier producto o servicio que ofrecemos o
        proporcionamos a través de él. De cualquier otra manera que podamos
        describir cuando proporciones la información. Para cualquier otro
        propósito con tu consentimiento. También podemos usar tu información
        para contactarte sobre nuestros propios bienes y servicios y los de
        terceros que puedan ser de tu interés. Si no deseas que usemos tu
        información de esta manera, por favor marca la casilla correspondiente
        ubicada en el formulario en el que recopilamos tus datos (el formulario
        de registro o la página de pago). Para más información, consulta
        "Opciones Sobre Cómo Usamos y Divulgamos Tu Información".
      </p>

      <h2>Divulgación de Tu Información</h2>

      <p>
        Podemos divulgar información agregada sobre nuestros usuarios, e
        información que no identifique a ningún individuo, sin restricción.
      </p>

      <p>
        Podemos divulgar información personal que recopilamos o que proporcionas
        como se describe en esta política de privacidad:
      </p>

      <p>
        A nuestras subsidiarias y afiliados. A contratistas, proveedores de
        servicios y otros terceros que utilizamos para apoyar nuestro negocio. A
        un comprador o sucesor en el caso de una fusión, desinversión,
        reestructuración, reorganización, disolución u otra venta o
        transferencia de algunos o todos los activos de PiattoQR, ya sea como
        empresa en marcha o como parte de una quiebra, liquidación o
        procedimiento similar, en el cual la información personal en poder de
        PiattoQR sobre los usuarios de nuestro Servicio esté entre los activos
        transferidos. Para cumplir el propósito para el cual la proporcionas.
        Para cualquier otro propósito divulgado por nosotros cuando proporcionas
        la información. Con tu consentimiento. Para cumplir con cualquier orden
        judicial, ley o proceso legal, incluyendo para responder a cualquier
        solicitud del gobierno o regulatoria. Para hacer cumplir o aplicar
        nuestros Términos de Uso y otros acuerdos, incluyendo para facturación y
        cobro. Si creemos que la divulgación es necesaria o apropiada para
        proteger los derechos, propiedad o seguridad de PiattoQR, nuestros
        clientes u otros. Esto incluye el intercambio de información con otras
        compañías y organizaciones con el propósito de protección contra fraudes
        y reducción del riesgo de crédito.
      </p>

      <h2>Opciones Sobre Cómo Usamos y Divulgamos Tu Información</h2>

      <p>
        Nos esforzamos por proporcionarte opciones con respecto a la información
        personal que nos proporcionas. Hemos creado mecanismos para
        proporcionarte control sobre tu información:
      </p>

      <p>
        Tecnologías de Seguimiento y Publicidad. Puedes configurar tu navegador
        para rechazar todas o algunas cookies del navegador, o para alertarte
        cuando se envían cookies. Si deshabilitas o rechazas las cookies, ten en
        cuenta que algunas partes de este sitio pueden ser inaccesibles o no
        funcionar correctamente.
      </p>

      <p>
        Ofertas Promocionales de la Compañía. Si no deseas que tu dirección de
        correo electrónico/información de contacto sea utilizada por la Compañía
        para promocionar nuestros propios productos o servicios o los de
        terceros, puedes optar por no participar marcando la casilla
        correspondiente ubicada en el formulario en el que recopilamos tus datos
        o enviándonos un correo electrónico indicando tu solicitud a [inserta
        dirección de correo electrónico]. Si te hemos enviado un correo
        electrónico promocional, puedes enviarnos un correo electrónico de
        respuesta pidiendo ser omitido de futuras distribuciones de correos
        electrónicos. Esta exclusión no se aplica a la información proporcionada
        a la Compañía como resultado de una compra de producto, registro de
        garantía, experiencia de servicio de producto u otras transacciones.
      </p>

      <h2>Accediendo y Corrigiendo Tu Información</h2>

      <p>
        Puedes revisar y cambiar tu información personal iniciando sesión en el
        sitio web y visitando la página de perfil de tu cuenta.
      </p>

      <p>
        También puedes enviarnos un correo electrónico a [inserta dirección de
        correo electrónico] para solicitar acceso, corregir o eliminar cualquier
        información personal que nos hayas proporcionado. No podemos eliminar tu
        información personal excepto eliminando también tu cuenta de usuario. No
        podemos acomodar una solicitud de cambio de información si creemos que
        el cambio violaría cualquier ley o requisito legal o haría que la
        información sea incorrecta.
      </p>

      <p>
        Si eliminas tus Contribuciones de Usuario del Servicio, las copias de
        tus Contribuciones de Usuario pueden seguir siendo visibles en páginas
        en caché y archivadas, o podrían haber sido copiadas o almacenadas por
        otros usuarios del Servicio.
      </p>

      <h2>Seguridad de los Datos</h2>

      <p>
        Hemos implementado medidas diseñadas para asegurar tu información
        personal contra pérdida accidental y contra acceso, uso, alteración y
        divulgación no autorizados. Toda la información que nos proporcionas se
        almacena en nuestros servidores seguros detrás de firewalls.
      </p>

      <p>
        La seguridad y protección de tu información también depende de ti. Donde
        te hemos dado (o donde has elegido) una contraseña para acceder a
        ciertas partes de nuestro Servicio, eres responsable de mantener esta
        contraseña confidencial. Te pedimos que no compartas tu contraseña con
        nadie. Te instamos a tener cuidado al dar información en áreas públicas
        del Servicio como los tableros de mensajes. La información que compartes
        en áreas públicas puede ser vista por cualquier usuario del Servicio.
      </p>

      <h2>Cambios en Nuestra Política de Privacidad</h2>

      <p>
        Es nuestra política publicar cualquier cambio que hagamos a nuestra
        política de privacidad en esta página. Si realizamos cambios materiales
        en cómo tratamos la información personal de nuestros usuarios, te
        notificaremos a través de un aviso en la página de inicio del sitio web.
        La fecha en que la política de privacidad fue revisada por última vez se
        identifica en la parte superior de la página. Eres responsable de
        asegurarte de que tenemos una dirección de correo electrónico activa y
        entregable actualizada para ti, y de visitar periódicamente nuestro
        sitio web y esta política de privacidad para verificar cualquier cambio.
      </p>

      <h2>Información de Contacto</h2>

      <p>
        Para hacer preguntas o comentarios sobre esta política de privacidad y
        nuestras prácticas de privacidad, contáctanos en:
        alejandro.hernandez@piattoqr.com.
      </p>
    </div>
  );
}
