export default function TermsIt() {
  return (
    <div>
      <h2>Termini e Condizioni</h2>

      <p>Ultimo aggiornamento: 24/03/2024</p>

      <p>
        Si prega di leggere attentamente questi Termini e Condizioni ("Termini",
        "Termini e Condizioni") prima di utilizzare il sito web di PiattoQR e i
        servizi operati da PiattoQR (collettivamente indicati come il
        "Servizio").
      </p>

      <h2>1. Accordo ai Termini</h2>

      <p>
        Accedendo o utilizzando il Servizio, accetti di essere vincolato da
        questi Termini. Se non sei d'accordo con una parte qualsiasi dei
        Termini, non potrai accedere al Servizio.
      </p>

      <h2>2. Proprietà Intellettuale</h2>

      <p>
        Il Servizio e il suo contenuto originale, le caratteristiche e la
        funzionalità sono e rimarranno di proprietà esclusiva di PiattoQR e dei
        suoi licenziatari. Il Servizio è protetto da copyright, marchi e altre
        leggi sia di [inserisci giurisdizione] che di paesi stranieri. I nostri
        marchi e la nostra veste commerciale non possono essere utilizzati in
        relazione a nessun prodotto o servizio senza il previo consenso scritto
        di PiattoQR.
      </p>

      <h2>3. Uso del Servizio</h2>

      <p>
        3.1. PiattoQR ti concede una licenza limitata, non esclusiva, non
        trasferibile e revocabile per accedere e utilizzare il Servizio per i
        tuoi scopi personali o aziendali interni.
      </p>

      <h2>3.2. Accetti di non:</h2>

      <p>
        Modificare, adattare, tradurre o decodificare qualsiasi parte del
        Servizio. Utilizzare il Servizio per qualsiasi scopo illecito o in
        violazione di qualsiasi legge applicabile. Interferire con o
        interrompere l'integrità o le prestazioni del Servizio o di qualsiasi
        server o rete correlati. Tentare di ottenere accesso non autorizzato al
        Servizio o a qualsiasi sistema o rete correlati.
      </p>

      <h2>4. Account Utente</h2>

      <p>
        4.1. Per accedere a determinate funzionalità del Servizio, potrebbe
        essere necessario creare un account. Sei responsabile di mantenere la
        riservatezza del tuo account e della tua password e di limitare
        l'accesso al tuo account.
      </p>

      <p>
        4.2. Accetti di assumerti la responsabilità di tutte le attività che si
        verificano sotto il tuo account o password.
      </p>

      <h2>5. Terminazione</h2>

      <p>
        Possiamo terminare o sospendere il tuo accesso al Servizio
        immediatamente, senza preavviso o responsabilità, per qualsiasi motivo,
        incluso senza limitazione se violi i Termini.
      </p>

      <p>
        Al termine, il tuo diritto di utilizzare il Servizio cesserà
        immediatamente.
      </p>

      <h2>6. Limitazione di Responsabilità</h2>

      <p>
        In nessun caso PiattoQR, né i suoi direttori, dipendenti, partner,
        agenti, fornitori o affiliati saranno responsabili per qualsiasi danno
        indiretto, incidentale, speciale, consequenziale o punitivo, inclusi
        senza limitazione, perdita di profitti, dati, uso, buona volontà o altre
        perdite intangibili, risultanti da (i) il tuo accesso o utilizzo o
        incapacità di accedere o utilizzare il Servizio; (ii) qualsiasi condotta
        o contenuto di terzi sul Servizio; (iii) qualsiasi contenuto ottenuto
        dal Servizio; e (iv) accesso non autorizzato, utilizzo o alterazione
        delle tue trasmissioni o contenuti, sia basato su garanzia, contratto,
        illecito (inclusa negligenza) o qualsiasi altra teoria legale, sia o no
        siamo stati informati della possibilità di tali danni, e anche se una
        soluzione stabilita nel presente ha fallito nel suo scopo essenziale.
      </p>

      <h2>7. Legge Applicabile</h2>

      <p>
        Questi Termini saranno regolati e interpretati in conformità con le
        leggi di [inserisci giurisdizione], senza riguardo alle sue disposizioni
        sui conflitti di legge.
      </p>

      <p>
        La nostra mancata applicazione di qualsiasi diritto o disposizione di
        questi Termini non sarà considerata una rinuncia a tali diritti. Se una
        qualsiasi disposizione di questi Termini è ritenuta invalida o
        inapplicabile da un tribunale, le restanti disposizioni di questi
        Termini rimarranno in vigore.
      </p>

      <h2>8. Modifiche</h2>

      <p>
        Ci riserviamo il diritto, a nostra sola discrezione, di modificare o
        sostituire questi Termini in qualsiasi momento. Se una revisione è
        sostanziale, cercheremo di fornire almeno 30 giorni di preavviso prima
        che i nuovi termini diventino effettivi. Ciò che costituisce una
        modifica sostanziale sarà determinato a nostra sola discrezione.
      </p>

      <p>
        Continuando ad accedere o utilizzare il nostro Servizio dopo che tali
        revisioni diventano effettive, accetti di essere vincolato dai termini
        rivisti. Se non sei d'accordo con i nuovi termini, ti preghiamo di
        smettere di utilizzare il Servizio.
      </p>

      <h2>9. Contattaci</h2>

      <p>
        Se hai domande su questi Termini, ti preghiamo di contattarci
        all'indirizzo alejandro.hernandez@piattoqr.com.
      </p>
    </div>
  );
}
