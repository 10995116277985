export default function TermsEs() {
  return (
    <div>
      <h2>Términos y Condiciones</h2>

      <p>Última actualización: 24/03/2024</p>

      <p>
        Por favor, lea estos Términos y Condiciones ("Términos", "Términos y
        Condiciones") cuidadosamente antes de usar el sitio web de PiattoQR y
        los servicios operados por PiattoQR (colectivamente referidos como el
        "Servicio").
      </p>

      <h2>1. Aceptación de los Términos</h2>

      <p>
        Al acceder o usar el Servicio, usted acepta estar sujeto a estos
        Términos. Si no está de acuerdo con alguna parte de los Términos, no
        podrá acceder al Servicio.
      </p>

      <h2>2. Propiedad Intelectual</h2>

      <p>
        El Servicio y su contenido original, características y funcionalidad son
        y seguirán siendo propiedad exclusiva de PiattoQR y sus licenciantes. El
        Servicio está protegido por derechos de autor, marcas registradas y
        otras leyes tanto de [inserte jurisdicción] como de países extranjeros.
        Nuestras marcas registradas y presentación comercial no pueden ser
        utilizadas en relación con ningún producto o servicio sin el previo
        consentimiento por escrito de PiattoQR.
      </p>

      <h2>3. Uso del Servicio</h2>

      <p>
        3.1. PiattoQR le concede una licencia limitada, no exclusiva, no
        transferible y revocable para acceder y usar el Servicio para sus fines
        personales o internos de negocios.
      </p>

      <h2>3.2. Usted acepta no:</h2>

      <p>
        Modificar, adaptar, traducir o realizar ingeniería inversa de cualquier
        parte del Servicio. Usar el Servicio para cualquier propósito ilegal o
        en violación de cualquier ley aplicable. Interferir con o interrumpir la
        integridad o el rendimiento del Servicio o cualquier servidor o red
        relacionada. Intentar obtener acceso no autorizado al Servicio o
        cualquier sistema o red relacionada.
      </p>

      <h2>4. Cuentas de Usuario</h2>

      <p>
        4.1. Para acceder a ciertas características del Servicio, puede ser
        necesario crear una cuenta. Usted es responsable de mantener la
        confidencialidad de su cuenta y contraseña y de restringir el acceso a
        su cuenta.
      </p>

      <p>
        4.2. Usted acepta responsabilizarse de todas las actividades que ocurran
        bajo su cuenta o contraseña.
      </p>

      <h2>5. Terminación</h2>

      <p>
        Podemos terminar o suspender su acceso al Servicio de inmediato, sin
        previo aviso o responsabilidad, por cualquier motivo, incluyendo sin
        limitación si usted incumple los Términos.
      </p>

      <p>Al finalizar, su derecho a usar el Servicio cesará de inmediato.</p>

      <h2>6. Limitación de Responsabilidad</h2>

      <p>
        En ningún caso PiattoQR, ni sus directores, empleados, socios, agentes,
        proveedores o afiliados serán responsables por cualquier daño indirecto,
        incidental, especial, consecuente o punitivo, incluyendo sin limitación,
        pérdida de beneficios, datos, uso, buena voluntad u otras pérdidas
        intangibles, resultantes de (i) su acceso o uso o incapacidad de acceder
        o usar el Servicio; (ii) cualquier conducta o contenido de un tercero en
        el Servicio; (iii) cualquier contenido obtenido del Servicio; y (iv)
        acceso no autorizado, uso o alteración de sus transmisiones o contenido,
        ya sea basado en garantía, contrato, agravio (incluyendo negligencia) o
        cualquier otra teoría legal, ya sea o no hemos sido informados de la
        posibilidad de tales daños, e incluso si una solución establecida en el
        presente ha fallado en su propósito esencial.
      </p>

      <h2>7. Ley Aplicable</h2>

      <p>
        Estos Términos se regirán e interpretarán de acuerdo con las leyes de
        [inserte jurisdicción], sin tener en cuenta sus disposiciones sobre
        conflictos de leyes.
      </p>

      <p>
        Nuestra falta de hacer cumplir cualquier derecho o disposición de estos
        Términos no se considerará una renuncia a tales derechos. Si alguna
        disposición de estos Términos es considerada inválida o inaplicable por
        un tribunal, las disposiciones restantes de estos Términos seguirán en
        vigor.
      </p>

      <h2>8. Cambios</h2>

      <p>
        Nos reservamos el derecho, a nuestra sola discreción, de modificar o
        reemplazar estos Términos en cualquier momento. Si una revisión es
        material, trataremos de proporcionar al menos 30 días de aviso antes de
        que los nuevos términos entren en vigencia. Lo que constituye un cambio
        material se determinará a nuestra sola discreción.
      </p>

      <p>
        Al continuar accediendo o usando nuestro Servicio después de que esas
        revisiones se vuelvan efectivas, usted acepta estar sujeto a los
        términos revisados. Si usted no está de acuerdo con los nuevos términos,
        por favor deje de usar el Servicio.
      </p>

      <h2>9. Contáctenos</h2>

      <p>
        Si tiene alguna pregunta sobre estos Términos, por favor contáctenos en
        alejandro.hernandez@piattoqr.com.
      </p>
    </div>
  );
}
